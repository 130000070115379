const { default: ActionTypes } = require('../actionTypes')

const getBatches = (params) => {
  return {
    type: ActionTypes.batch.GET_BATCHES_REQUESTED,
    params,
  }
}

const getSortedBatches = (params) => {
  return {
    type: ActionTypes.batch.GET_SORTED_BATCHES_REQUESTED,
    params,
  }
}

const batchActions = {
  getBatches,
  getSortedBatches,
}

export default batchActions
