import { call, put, takeEvery } from '@redux-saga/core/effects'
import axios from 'axios'
import { apiCall } from 'src/helpers/api'
import Actions from '../actions'
import ActionTypes from '../actionTypes'

Array.prototype.getDuplicates = function () {
  var duplicates = {}
  let x = []
  for (var i = 0; i < this.length; i++) {
    if (duplicates.hasOwnProperty(this[i])) {
      duplicates[this[i]].push(i)
      console.log(duplicates[this[i]])
      x.push(duplicates[this[i]][0])
      duplicates[this[i]].shift()
    } else if (this.lastIndexOf(this[i]) !== i) {
      duplicates[this[i]] = [i]
    }
  }

  return x.sort().reverse()
}

function* handleGetUsers() {
  try {
    let dataArray = []
    let dataArrayDuplicates = []
    const res = yield call(apiCall, 'get', 'users/')
    const users = res.data.data
    let userData
    console.log(users)
    for (let i = 0; i < users.length; i++) {
      userData = users[i].returnValues
      dataArray.push(userData)
      dataArrayDuplicates.push(userData.user)
    }
    console.log(dataArrayDuplicates)
    let s = dataArrayDuplicates.getDuplicates()

    for (let i = 0; i < s.length; i++) {
      dataArray.splice(s[i], 1)
    }
    yield put({ type: ActionTypes.user.GET_USERS_SUCCESS, users: dataArray })
  } catch (err) {
    yield put({ type: ActionTypes.user.GET_USERS_FAILED, error: err.message })
  }
}

function* watcherUserSaga() {
  yield takeEvery(ActionTypes.user.GET_USERS_REQUESTED, handleGetUsers)
}

export default watcherUserSaga
