import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import axios from 'axios'
import { apiCall } from 'src/helpers/api'
import Actions from '../actions'
import ActionTypes from '../actionTypes'

function* handleGetBatches(params) {
  if (!params.params.type) {
    let farmerCount = 0
    let pre_logisticCount = 0
    let pre_productionCount = 0
    let productionCount = 0
    let dataArray = []
    /*if (params.params.role == 'PRE-PRODUCTION') {
      try {
        const res = yield call(apiCall, 'get', 'assign/' + params.params.address)
        let assignedBatchNos = res.data.data
        for (let i = 0; i < assignedBatchNos.length; i++) {
          let batch = assignedBatchNos[i]
          const res3 = yield call(apiCall, 'get', 'batches/' + batch)
          let batches = res3.data.data
          const batchNo = batches.returnValues.batchNo
          const desc = batches.returnValues.desc
          const date = batches.returnValues.cDate
          const batchId = batches.returnValues.batchId
          const transactionHash = batches.transactionHash

          const res2 = yield call(apiCall, 'get', 'batches/' + batch + '/status')
          const status = res2.data.data
          if (status != 'DONE' && status != 'PRODUCTION') {
            let data = {
              batchNo: batchNo,
              transactionHash: transactionHash,
              status: status,
              desc: desc,
              date: date,
              batchId: batchId,
            }
            dataArray.push(data)
          }
        }

        yield put({ type: ActionTypes.batch.GET_BATCHES_SUCCESS, batches: dataArray })
      } catch (err) {
        yield put({ type: ActionTypes.batch.GET_BATCHES_FAILED, error: err.message })
      }
    } else {*/
    try {
      const res = yield call(apiCall, 'get', 'batches/')
      //const batches = res.data.data.slice(params.params.index1, params.params.index2)

      let rawBatches = res.data.data.reverse()
      let totalCount = rawBatches.length

      let pageCount = rawBatches.length / 10
      if (pageCount > Math.floor(pageCount)) {
        pageCount = Math.floor(pageCount) + 1
      }
      let batches = rawBatches.slice(params.params.index1, params.params.index2)

      console.log(batches)
      for (let index = 0; index < batches.length; index++) {
        let batch = batches[index]
        const batchNo = batch.returnValues.batchNo
        const desc = batch.returnValues.desc
        const date = batch.returnValues.cDate
        const batchId = batch.returnValues.batchId
        const transactionHash = batch.transactionHash

        const res5 = yield call(apiCall, 'get', 'farmer/' + batch.returnValues.batchNo)
        let farmerValues = res5.data.data
        console.log(farmerValues)

        const res6 = yield call(apiCall, 'get', 'prelogistic/' + batch.returnValues.batchNo)
        let prelogisticValues = res6.data.data
        console.log(prelogisticValues)
        const plateNo = prelogisticValues[2]

        const seedClass = farmerValues[4]
        const parcel = farmerValues[5]
        const res2 = yield call(apiCall, 'get', 'batches/' + batch.returnValues.batchNo + '/status')
        const status = res2.data.data

        switch (status) {
          case 'FARMER':
            farmerCount++
            break
          case 'PRE-LOGISTIC-UPDATE':
            pre_logisticCount++
            break
          case 'PRE-LOGISTIC-FINISH':
            pre_logisticCount++
            break
          case 'PRE-PRODUCTION':
            pre_productionCount++
            break
          case 'PRODUCTION':
            productionCount++
            break
          default:
          // code block
        }

        let data = {
          batchNo: batchNo,
          transactionHash: transactionHash,
          status: status,
          desc: desc,
          date: date,
          batchId: batchId,
          seedClass: seedClass,
          parcel: parcel,
          plateNo: plateNo,
        }
        dataArray.push(data)
      }

      let widgetData = {
        farmerCount: farmerCount,
        pre_logisticCount: pre_logisticCount,
        pre_productionCount: pre_productionCount,
        productionCount: productionCount,
      }
      yield put({
        type: ActionTypes.batch.GET_BATCHES_SUCCESS,
        totalCount: totalCount,
        pageCount: pageCount,
        batches: dataArray,
      })
    } catch (err) {
      yield put({ type: ActionTypes.batch.GET_BATCHES_FAILED, error: err.message })
    }
  } else {
    let allData = []
    let farmerData = []
    let preProductionData = []
    let preLogisticData = []
    let productionData = []

    try {
      const res = yield call(apiCall, 'get', 'batches/')
      const batches = res.data.data
      for (let index = 0; index < batches.length; index++) {
        let batch = batches[index]
        const res6 = yield call(apiCall, 'get', 'batches/' + batch.returnValues.batchNo + '/status')
        let status = res6.data.data
        if (params.params.type == 'farmer') {
          const res3 = yield call(apiCall, 'get', 'farmer/' + batch.returnValues.batchNo)
          let sortedData = res3.data.data
          let innerFarmerData = {
            desc: batch.returnValues.desc,
            status: status,
            batchNo: batch.returnValues.batchNo,
            batchId: batch.returnValues.batchId,
            date: batch.returnValues.cDate,
            harvestType: sortedData[0],
            waterSource: sortedData[1],
            waterType: sortedData[2],
            farmer: sortedData[3],
            seedClass: sortedData[4],
            parcel: sortedData[5],
            region: sortedData[6],
          }
          sortedData.push(batch.returnValues.batchNo)
          farmerData.push(innerFarmerData)
        } else if (
          params.params.type == 'prelogistic' &&
          status != 'FARMER' &&
          status != 'PRE-LOGISTIC-UPDATE'
        ) {
          const res4 = yield call(apiCall, 'get', 'prelogistic/' + batch.returnValues.batchNo)
          let sortedData2 = res4.data.data
          console.log(sortedData2)
          let innerPreLogisticData = {
            desc: batch.returnValues.desc,
            status: status,
            batchNo: batch.returnValues.batchNo,
            batchId: batch.returnValues.batchId,
            date: batch.returnValues.cDate,
            transportationType: sortedData2[0],
            fieldDistance: sortedData2[1],
            vehicleNo: sortedData2[2],
          }
          preLogisticData.push(innerPreLogisticData)
        } else if (
          params.params.type == 'preproduction' &&
          status != 'FARMER' &&
          status != 'PRE-LOGISTIC-UPDATE' &&
          status != 'PRE-LOGISTIC-FINISH'
        ) {
          const res5 = yield call(apiCall, 'get', 'preproduction/' + batch.returnValues.batchNo)
          let sortedData3 = res5.data.data
          console.log(sortedData3)
          let innerPreProductionData = {
            desc: batch.returnValues.desc,
            status: status,
            batchNo: batch.returnValues.batchNo,
            batchId: batch.returnValues.batchId,
            date: batch.returnValues.cDate,
            company: sortedData3[0],
          }
          preProductionData.push(innerPreProductionData)
          console.log(sortedData3)
        } else if (params.params.type == 'production' && status == 'DONE') {
          const res6 = yield call(apiCall, 'get', 'production/' + batch.returnValues.batchNo)
          let sortedData4 = res6.data.data
          console.log(sortedData4)
          let innerProductionData = {
            desc: batch.returnValues.desc,
            status: status,
            batchNo: batch.returnValues.batchNo,
            batchId: batch.returnValues.batchId,
            date: batch.returnValues.cDate,
            lotNumber: sortedData4[0],
            productionDate: sortedData4[1],
          }
          productionData.push(innerProductionData)
          console.log(sortedData4)
        }
      }
      console.log(params.params.type)
      if (params.params.type == 'farmer') {
        yield put({ type: ActionTypes.batch.GET_BATCHES_SUCCESS, batches: farmerData })
      } else if (params.params.type == 'prelogistic') {
        yield put({
          type: ActionTypes.batch.GET_BATCHES_SUCCESS,
          batches: preLogisticData,
        })
      } else if (params.params.type == 'preproduction') {
        yield put({
          type: ActionTypes.batch.GET_BATCHES_SUCCESS,
          batches: preProductionData,
        })
      } else if (params.params.type == 'production') {
        yield put({
          type: ActionTypes.batch.GET_BATCHES_SUCCESS,
          batches: productionData,
        })
      }
    } catch (err) {
      yield put({ type: ActionTypes.batch.GET_BATCHES_FAILED, error: err.message })
    }
  }
}

function* handleGetSortedBatches(params) {
  let totalData = []

  let batchNo = params.params.batchNo
  let status = params.params.status

  const res = yield call(apiCall, 'get', 'farmer/' + batchNo)
  let data = res.data.data
  let innerFarmerData = {
    Hasat_Tipi: data[0],
    Su_Kaynağı: data[1],
    Sulama_Tipi: data[2],
    Yapılan_Tarım: data[3],
    Fide_Çeşidi: data[4],
    Parsel_Adı: data[5],
    Bölge: data[6],
  }

  const res2 = yield call(apiCall, 'get', 'prelogistic/' + batchNo)
  let data2 = res2.data.data

  let innerPreLogisticData = {
    Taşıma_Tipi: data2[0],
    Tarla_Uzaklığı: data2[1],
    Araç_No: data2[2],
  }

  const res3 = yield call(apiCall, 'get', 'preproduction/' + batchNo)
  let data3 = res3.data.data

  let innerPreProductionData = {
    İşletme: data3[0],
  }

  const res4 = yield call(apiCall, 'get', 'production/' + batchNo)
  let data4 = res4.data.data
  console.log(data4)
  let innerProductionData = {
    Lot_Number: data4[0],
    Production_Date: data4[1],
  }

  let innerTotalData = {
    tarla: innerFarmerData,
    lojistik: innerPreLogisticData,
    'mal Kabul': innerPreProductionData,
    //production: innerProductionData,
  }

  totalData.push(innerTotalData)

  yield put({
    type: ActionTypes.batch.GET_SORTED_BATCHES_SUCCESS,
    sortedBatches: innerTotalData,
  })
}

function* watcherBatchSaga() {
  yield takeEvery(ActionTypes.batch.GET_BATCHES_REQUESTED, handleGetBatches)
}

function* watcherSortedBatchSaga() {
  yield takeEvery(ActionTypes.batch.GET_SORTED_BATCHES_REQUESTED, handleGetSortedBatches)
}

export default function* batchesSaga() {
  yield all([fork(watcherBatchSaga), fork(watcherSortedBatchSaga)])
}
