const { default: ActionTypes } = require('../actionTypes')

const getWidgets = (params) => {
  return {
    type: ActionTypes.widget.GET_WIDGETS_REQUESTED,
    params,
  }
}

const widgetActions = {
  getWidgets,
}

export default widgetActions
