const { default: ActionTypes } = require('../actionTypes')

const setCurrentUser = (currentUser) => ({
  type: ActionTypes.currentUser.SET_CURRENT_USER,
  payload: currentUser,
})

const getCurrentUser = () => {
  return {
    type: ActionTypes.currentUser.GET_CURRENT_USER,
  }
}

const currentUserActions = {
  setCurrentUser,
  getCurrentUser,
}

export default currentUserActions
