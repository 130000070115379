const { default: ActionTypes } = require('../actionTypes')

const setSidebar = (users) => ({
  type: ActionTypes.sidebar.SET_SIDEBAR,
  payload: users,
})

const getSidebar = () => ({
  type: ActionTypes.sidebar.GET_SIDEBAR,
})

const currentSidebarActions = {
  setSidebar,
  getSidebar,
}

export default currentSidebarActions
