import userActions from './userActions'
import currentUserActions from './currentUserActions'
import batchActions from './batchActions'
import sidebarActions from './sidebarActions'
import widgetActions from './widgetActions'
const Actions = {
  userActions,
  sidebarActions,
  batchActions,
  widgetActions,
  currentUserActions,
}

export default Actions
