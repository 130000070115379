import createSagaMiddleware from '@redux-saga/core'
import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'
import rootSaga from './saga/rootSaga'
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(reducers,composeWithDevTools(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default store
