import ActionTypes from '../actionTypes'

const currentUserReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.currentUser.GET_CURRENT_USER:
      return action.payload
    default:
      return {
        ...state,
      }
  }
}

export default currentUserReducer
