import * as user from './user'
import * as currentUser from './currentUser'
import * as batch from './batch'
import * as sidebar from './sidebar'
import * as widget from './widget'
const ActionTypes = {
  user,
  sidebar,
  batch,
  widget,
  currentUser,
}

export default ActionTypes
