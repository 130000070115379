import Actions from '../actions'
import ActionTypes from '../actionTypes'

const initialState = {
  widgets: [],
  loading: false,
  error: null,
}

const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.widget.GET_WIDGETS_REQUESTED:
      return { ...state, loading: true }
    case ActionTypes.widget.GET_WIDGETS_SUCCESS:
      return { ...state, loading: false, widgets: action.widgets }
    case ActionTypes.widget.GET_WIDGETS_FAILED:
      return { ...state, loading: false, error: action.message }
    default:
      return state
  }
}

export default widgetReducer
