const { default: ActionTypes } = require('../actionTypes')

const setUsers = (users) => ({
  type: ActionTypes.user.SET_USERS,
  payload: users,
})

const getUsers = () => {
  return {
    type: ActionTypes.user.GET_USERS_REQUESTED,
  }
}

const userActions = {
  setUsers,
  getUsers,
}

export default userActions
