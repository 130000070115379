import { combineReducers } from 'redux'
import userReducer from './userReducer'
import batchReducer from './batchReducer'
import sidebarReducer from './sidebarReducer'
import widgetReducer from './widgetReducer'
import currentUserReducer from './currentUserReducer'

const reducers = combineReducers({
  users: userReducer,
  batches: batchReducer,
  coreui: sidebarReducer,
  widgets: widgetReducer,
  currentUser: currentUserReducer,
})

export default reducers
