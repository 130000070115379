import { call, put, takeEvery } from '@redux-saga/core/effects'
import axios from 'axios'
import { apiCall } from 'src/helpers/api'
import Actions from '../actions'
import ActionTypes from '../actionTypes'

function* handleGetWidgets(params) {
  let farmerCount = 0
  let pre_logisticCount = 0
  let pre_productionCount = 0
  let productionCount = 0

  console.log(params.params)
  if (params.params == 'BATCH') {
    try {
      console.log('try catch')
      const res = yield call(apiCall, 'get', 'batches/')
      const batches = res.data.data
      console.log(batches)
      for (let index = 0; index < batches.length; index++) {
        let batch = batches[index]

        const res2 = yield call(apiCall, 'get', 'batches/' + batch.returnValues.batchNo + '/status')
        const status = res2.data.data

        switch (status) {
          case 'FARMER':
            farmerCount++
            break
          case 'PRE-LOGISTIC-UPDATE':
            pre_logisticCount++
            break
          case 'PRE-LOGISTIC-FINISH':
            pre_logisticCount++
            break
          case 'PRE-PRODUCTION':
            pre_productionCount++
            break
            case 'PRODUCTION':
              productionCount++
              break
          default:
          // code block
        }
      }

      let widgetData = {
        farmerCount: farmerCount,
        pre_logisticCount: pre_logisticCount,
        pre_productionCount: pre_productionCount,
        productionCount: productionCount,
      }
      yield put({ type: ActionTypes.widget.GET_WIDGETS_SUCCESS, widgets: widgetData })
    } catch (err) {
      yield put({ type: ActionTypes.widget.GET_WIDGETS_FAILED, error: err.message })
    }
  } else {
    try {
      let farmerUserCount = 0
      let preproductionUserCount = 0
      let productionUserCount = 0

      const res = yield call(apiCall, 'get', 'users/')
      const users = res.data.data
      console.log(users)
      for (let i = 0; i < users.length; i++) {
        if (users[i].returnValues.role == 'FARMER') {
          farmerUserCount++
        } else if (users[i].returnValues.role == 'PRE-PRODUCTION') {
          preproductionUserCount++
        } else if (users[i].returnValues.role == 'PRODUCTION') {
          productionUserCount++
        }
      }

      let widgetData = {
        farmerUserCount: farmerUserCount,
        preproductionUserCount: preproductionUserCount,
        productionUserCount: productionUserCount,
      }

      yield put({ type: ActionTypes.widget.GET_WIDGETS_SUCCESS, widgets: widgetData })
    } catch (err) {
      yield put({ type: ActionTypes.widget.GET_WIDGETS_FAILED, error: err.message })
    }
  }
}

function* watcherWidgetSaga() {
  yield takeEvery(ActionTypes.widget.GET_WIDGETS_REQUESTED, handleGetWidgets)
}

export default watcherWidgetSaga
