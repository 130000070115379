import Actions from '../actions'
import ActionTypes from '../actionTypes'

const initialState = {
  batches: [],
  sortedBatches: [],
  loading: false,
  error: null,
  totalCount: 20,
}

const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.batch.GET_BATCHES_REQUESTED:
      return { ...state, loading: true }
    case ActionTypes.batch.GET_BATCHES_SUCCESS:
      return { ...state, loading: false, batches: action.batches, totalCount: action.totalCount }
    case ActionTypes.batch.GET_BATCHES_FAILED:
      return { ...state, loading: false, error: action.message }
    case ActionTypes.batch.GET_SORTED_BATCHES_REQUESTED:
      return { ...state, loading: true }
    case ActionTypes.batch.GET_SORTED_BATCHES_SUCCESS:
      return { ...state, loading: false, sortedBatches: action.sortedBatches }
    case ActionTypes.batch.GET_SORTED_BATCHES_FAILED:
      return { ...state, loading: false, error: action.message }
    default:
      return state
  }
}

export default batchReducer
