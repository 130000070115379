import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min'
import axios2 from  './helpers/axios'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)



// Containers

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

if (window.location.pathname == '/' || window.location.pathname == '/login') {
  sessionStorage.setItem('history', '/dashboard')
} else {
  sessionStorage.setItem('history', window.location.href)
}

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const viewBatch = React.lazy(() => import('./views/viewBatch/ViewBatch'))

const isAuthenticated = () => {
  let address = sessionStorage.getItem('address')
  if (!address) {
    return false
  } else {
    return true
  }
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />)}
  />
)

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)}
  />
)

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route exact path="/viewBatch" name="Page 500" component={viewBatch} />
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
}

UnauthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
}
