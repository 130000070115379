import axios from  'axios'

require('dotenv').config();


export const apiCall = async (method, path, data, params, responseType) => {
  const res = await axios({
    method,
    url:  `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}/${path}` ,
    data,
    params,
    responseType,
  })
  return res
}
