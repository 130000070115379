import Actions from '../actions'
import ActionTypes from '../actionTypes'

const initialState = {
  users: [],
  loading: false,
  error: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.user.GET_USERS_REQUESTED:
      return { ...state, loading: true }
    case ActionTypes.user.GET_USERS_SUCCESS:
      return { ...state, loading: false, users: action.users }
    case ActionTypes.user.GET_USERS_FAILED:
      return { ...state, loading: false, error: action.message }
    default:
      return state
  }
}

export default userReducer
